<div mat-dialog-title class="title" fxLayout fxLayoutAlign="space-between center">
  <div>User Details</div>
  <div>
    <button mat-icon-button [mat-dialog-close] aria-label="Close User Details Dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="content" fxLayout="column" (scroll)="scrollCheck($event)">
  <div class="error" *ngIf="error">{{error}}</div>
  <div class="details-list" *ngIf="!editUser">
    <div>
      <h3 matLine>Username</h3>
      <p matLine>{{data.username || 'loading...'}}</p>
    </div>
    <mat-divider></mat-divider>
    <div>
      <h3 matLine>Name</h3>
      <p matLine>{{data.getFullName()}}</p>
    </div>
    <mat-divider></mat-divider>
    <div>
      <h3 matLine>Phone number</h3>
      <p matLine class>
        {{
          loading ? 'loading...' : (
            (
              data.phone && data.phone !== ''
            ) ? data.getFormattedPhone() : 'N/A'
          )
        }}
      </p>
    </div>
    <mat-divider></mat-divider>
    <div>
      <h3 matLine>Email address</h3>
      <p matLine>{{data.email || 'loading...'}}</p>
    </div>
    <mat-divider></mat-divider>
    <div>
      <h3 matLine>Organization</h3>
      <p matLine>{{data.company.name}}</p>
    </div>
    <mat-divider></mat-divider>
    <div>
      <h3 matLine>Groups</h3>
      <p matLine>{{data.groupsToString()}}</p>
    </div>
    <mat-divider *ngIf="isAdmin"></mat-divider>
    <div *ngIf="isAdmin">
      <h3 matLine>Last access time</h3>
      <p matLine>{{
        data.lastAccessed ?
          (data.lastAccessed | date:globals.DATETIME_FORMAT:project.getOffset(data.lastAccessed)) :
          'N/A'
      }}</p>
    </div>
  </div>

  <form *ngIf="editUser" [formGroup]="editUserForm">
    <div fxLayout="column" class="form-container">
      <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px">
        <mat-form-field fxFlex="calc(50%-16px)">
          <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>
        <mat-form-field fxFlex="calc(50%-16px)">
          <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>
      </div>
      <mat-form-field>
        <input matInput placeholder="Phone" type="tel" formControlName="phone">
        <span matPrefix>+1&nbsp;</span>
        <mat-error *ngIf="editUserForm.controls['phone'].invalid">
          must be 10 numbers (including area code)
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="isAdmin">
        <mat-select placeholder="Groups" formControlName="groups" [compareWith]="compareGroups" multiple>
          <mat-option *ngFor="let group of groups" [value]="group">
            {{group.name}}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="groups.length === 0">There are no groups in {{data.company.name}}.</mat-hint>
      </mat-form-field>
      <div class="checkbox-container" *ngIf="isAdmin">
          <mat-checkbox formControlName="admin">Administrator</mat-checkbox>
        </div>
    </div>
  </form>
</mat-dialog-content>


<div mat-dialog-actions fxLayoutAlign="end center" *ngIf="isAdmin || isCurrentUser">
  <div fxLayout *ngIf="!editUser && !showDeleteCheck">
    <button mat-raised-button
      color="warn"
      [disabled]="loading || isCurrentUser || data.role === 0"
      (click)="showDeleteCheck = true">
      Delete
    </button>
    <button mat-raised-button color="primary" [disabled]="loading" (click)="toggleEditor()">Edit</button>
  </div>

  <div fxLayout *ngIf="!editUser && showDeleteCheck">
    <button mat-raised-button color="warn" [disabled]="loading" (click)="onDelete()">Delete {{data.getFullName()}}?</button>
    <button mat-raised-button [disabled]="loading" (click)="showDeleteCheck = false">Cancel</button>
  </div>

  <div fxLayout *ngIf="editUser">
    <button mat-raised-button (click)="toggleEditor()">Cancel</button>
    <button type="submit"
      color="primary"
      [disabled]="loading || editUserForm.pristine || editUserForm.invalid"
      (click)="onSave()"
      mat-raised-button>{{loading ? 'Loading' : 'Save'}}</button>
  </div>
</div>
