import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Event, Params } from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteDataService {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  getData(): Observable<any> {
    return from(this.router.events)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        map(route => route.data['value'])
    );
  }

  getQueryParams(): Observable<Params> {
    //Get query parameters, but only once they've all arrived
    return from(this.router.events)
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        mergeMap(() => this.activatedRoute.queryParams)
      );
  }
}
