<div fxLayout="column" fxFill>
  <app-navbar class="app-component"></app-navbar>
  <div *ngIf="!vaiUser" fxLayout fxLayoutAlign="center center" fxFlex>
    <amplify-authenticator>
      <amplify-sign-in slot="sign-in" header-text="VIP Sign-in" hide-sign-up></amplify-sign-in>
      <mat-spinner diameter="60" *ngIf="!error && !showVerify"></mat-spinner>
      <div class="container" *ngIf="!error && showVerify" fxLayout="column" fxFlex>
        <div>
          <h3>Verify code sent to email</h3>
        </div>
        <div fxLayout="column" fxFlex fxLayoutGap="8px">
          <form class="form" [formGroup]="confirmForm" (ngSubmit)="onSubmit()" fxFlex fxLayout="column">
            <div fxLayout fxLayoutAlign="end center" fxLayoutGap="8px">
              <mat-form-field floatLabel="always">
                <mat-label>OTP Code</mat-label>
                <input matInput formControlName="code">
              </mat-form-field>
              <mat-error *ngIf="confirmError">Invalid Code</mat-error>
              <button type="button" mat-raised-button (click)="onResend()">Resend</button>
            </div>
            <div fxLayout fxLayoutAlign="end center" fxLayoutGap="8px">
              <amplify-sign-out button-text="Cancel"></amplify-sign-out>
              <button class="submit" type="submit" mat-raised-button>Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div class="error-msg" *ngIf="error">
        <h3>Oops!</h3>
        <p>Something went wrong.</p>
      </div>
    </amplify-authenticator>
  </div>
  <router-outlet *ngIf="vaiUser"></router-outlet>
</div>
