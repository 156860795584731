import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/core';
import { HydrographService } from './hydrograph.service';
import * as c3 from 'c3';
import * as moment from 'moment-timezone';
import { LngLat } from 'mapbox-gl';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Time } from '@app/shared/models';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BCDataType } from '@app/features/what-if/what-if.models';

export interface POITime extends Time {
  fakeNow?: Date;
  fakeNRT?: boolean;
}

export interface BCChartData {
  dataType: string;
  times: number[];
  values: number[];
}

@Component({
  selector: 'app-hydrograph',
  templateUrl: './hydrograph.component.html',
  styleUrls: ['./hydrograph.component.scss'],
})
export class HydrographComponent implements OnInit, OnDestroy, AfterViewInit {
  private _id;
  private viewInit = new BehaviorSubject<boolean>(false);
  timezone: string;
  timestamp: number = null;
  chart: any;
  loading = true;
  error: string;
  chartId = 'chart-' + Date.now();
  private resizeHeight$ = new BehaviorSubject<number>(
    this.elRef.nativeElement.offsetHeight
  );
  private height: number;
  private resizeSub: Subscription;

  private _hideNow = false;
  get hideNow(): boolean {
    return this._hideNow;
  }
  @Input()
  set hideNow(value: boolean) {
    this._hideNow = coerceBooleanProperty(value);
  }
  @Input() times: POITime;
  @Input() showLegend = false;
  @Input() groupTooltip = false;
  @Input() attributes: string[] = [];
  @Input() whatif: string;
  @Input() lngLat: LngLat;
  @Input() set id(id: string) {
    this._id = id;
    this.error = '';
    this.loading = true;
    setTimeout(() =>
      this.getChartData().subscribe({
        next: (chartConfig) => {
          if (chartConfig) {
            this.chart = c3.generate(chartConfig);
            this.chart.flush();
          }
        },
        error: this.onError,
      })
    );
  }
  @Input() set update(timestamp: number) {
    if (timestamp > this.timestamp) {
      if (this.timestamp !== null && this.chart) {
        this.updateChart();
      }
      this.timestamp = timestamp;
    }
  }
  _boundaryCondition: BCChartData;
  @Input()
  get boundaryCondition() {
    return this._boundaryCondition;
  }
  set boundaryCondition (newBC) {
    const oldBC = this.boundaryCondition;
    this._boundaryCondition = newBC;
    if (!this.areBoundaryConditionsEqual(oldBC, newBC)) {
      this.updateChartFromBoundaryCondition();
    }
  }

  @HostListener('window:resize')
  onResize() {
    if (this.height !== this.elRef.nativeElement.offsetHeight) {
      this.height = this.elRef.nativeElement.offsetHeight;
      this.resizeHeight$.next(this.height);
    }
  }

  constructor(
    private elRef: ElementRef,
    private userService: UserService,
    private hydrographService: HydrographService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    if (!this.times && !this.whatif) {
      throw new TypeError(`'times' input parameter is required.`);
    }

    this.resizeSub = this.resizeHeight$
      .pipe(debounceTime(250))
      .subscribe((height) => this.chart?.resize({ height }));
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.viewInit.next(true);
    this.timezone = this.userService.project.timezone;
  }

  private updateChart = (): void => {
    if (this.chart) {
      this.loading = true;
      this.chart = this.chart.destroy();
      this.getChartData().subscribe({
        next: (config) => {
          this.loading = false;
          this.error = '';
          this.chart = c3.generate(config);
          this.chart.flush();
        },
        error: this.onError,
      });
    }
  }

  private updateChartFromBoundaryCondition = (): void => {
    if (this.chart && this.boundaryCondition && this.attributes[0] === 'boundary-conditions') {
      const newData = this.getBoundaryConditionChartUpdateData();
      const numTimes = newData.columns[0].length - 1;
      if (numTimes > 0) {
        this.chart.load(newData);
        const values = this.boundaryCondition.values;
        const margin = this.getBoundaryConditionChartYMargin();
        this.chart.axis.min(Math.min(...values) - margin);
        this.chart.axis.max(Math.max(...values) + margin);
      } else {
        this.chart.unload();
      }
    }
  }

  getChartData = (): Observable<any> => {
    const elWidth = this.elRef.nativeElement.offsetWidth;
    const elHeight = this.elRef.nativeElement.offsetHeight;
    let req;
    switch (this.attributes[0]) {
      case 'inundation':
        req = this.hydrographService.getInundation(
          this.lngLat,
          this.times,
          this.whatif
        );
        break;

      case 'velocity':
        req = this.hydrographService.getVelocity(this._id, this.times);
        break;

      case 'soil-moisture':
        req = this.hydrographService.getSoilMoisture(this._id, this.times);
        break;
      
      case 'boundary-conditions':
        req = this.getBoundaryConditionChartConfig();
        break;

      default:
        req = this.hydrographService.getRawData(
          this._id,
          this.times,
          this.whatif
        );
        break;
    }

    return req.pipe(
      map((res: any) => {
        const isNA = res?.status === 'NA';
        this.loading = false;
        if (!res || isNA) {
          const time = moment
            .tz(this.times.end, this.timezone)
            .format('YYYY/MM/DD HH:mm');
          const msg = isNA
            ? 'Inundation unavailable at this location for ' + time
            : 'Hydrograph unavailable';
          this.error = msg;
          return throwError(msg);
        }
        const chartConfig: any = this.hydrographService.getChartSettings({
          bindto: '#' + this.chartId,
          isInundation: this.attributes.includes('inundation'),
          isVelocity: this.attributes.includes('velocity'),
          timezone: this.userService.project.timezone,
          elHeight,
          elWidth,
          legend: this.showLegend,
          groupTooltip: this.groupTooltip,
          hideNow: this.hideNow,
          ...res,
        });

        if (!!this.times?.fakeNow) {
          chartConfig.grid.x.lines = [
            {
              value: this.times.fakeNow,
              text: `Time: ${moment
                .tz(this.times.fakeNow, this.userService.project.timezone)
                .format('HH:mm')}`,
            },
          ];
        }
        return chartConfig;
      })
    );
  };

  onError = (): void => {
    this.loading = false;
    this.error = 'Hydrograph unavailable';
  };

  private getBoundaryConditionChartConfig = (): Observable<any> => {
    if (!this.boundaryCondition) {
      //No chart to show
      return of(null);
    } else {
      //Read the chart config JSON object from file
      return this.http.get<any>('/assets/what-if.bc-chart-config.json').pipe(map((res) => {
        //Insert all of the necessary data into the appropriate places in the config object
        //  (the file just has placeholder values for these properties)
        const times = this.boundaryCondition?.times || [];
        const values = this.boundaryCondition?.values || [];
        const yMargin = this.getBoundaryConditionChartYMargin();
        const min = values.length ? Math.min(...values) : 0;
        const max = values.length ? Math.max(...values) : 0;
        res.data.names['vflo-disc-y'] = this.getBoundaryConditionChartDataTypeDisplayName();
        res.data.colors['vflo-disc-y'] = this.getBoundaryConditionChartColor();
        res.data.columns[0] = res.data.columns[0].concat(times);
        res.data.columns[1] = res.data.columns[1].concat(values);
        res.data.max['vflo-disc-y'] = max;
        res.axis.y.label = this.getBoundaryConditionChartYAxisLabel();
        res.axis.y.min = min - yMargin;
        res.axis.y.max = max + yMargin;
        return res;
      }));
    }
  }

  private getBoundaryConditionChartUpdateData = (): {columns: any[][], unload: string[]} => {
    const headers = this.getBoundaryConditionChartHeaders();
    const times = this.boundaryCondition?.times || [];
    const values = this.boundaryCondition?.values || [];
    return {
      columns: [
        [headers[0], ...times],
        [headers[1], ...values]
      ],
      unload: headers
    };
  }

  private getBoundaryConditionChartHeaders = (): string[] => {
    const dataType = this.getBoundaryConditionChartDataType();
    switch (dataType) {
      case BCDataType.Stage:
        return ['vflo-stage-x', 'vflo-stage-y'];
      case BCDataType.Flow:
      default:
        return ['vflo-disc-x', 'vflo-disc-y'];
    }
  }

  private getBoundaryConditionChartYMargin = (): number => {
    return 1;
  }

  private getBoundaryConditionChartDataType = (): BCDataType => {
    return BCDataType.Flow;
  }

  private getBoundaryConditionChartColor = (): string => {
    const dataType = this.getBoundaryConditionChartDataType();
    switch (dataType) {
      case BCDataType.Stage:
        //dark red
        return '#8F2B21';
      case BCDataType.Flow:
        //light blue
        return '#1e8fff';
      default:
        return '#000000';
    }
  }

  private getBoundaryConditionChartYAxisLabel = (): string => {
    const dataType = this.getBoundaryConditionChartDataType();
    switch (dataType) {
      case BCDataType.Stage:
        return `Stage (${this.userService.project.getUnitConfig('stage').options[0].abbr})`;
      case BCDataType.Flow:
        return `Discharge (${this.userService.project.getUnitConfig('flow').options[0].abbr})`;
      default:
        return dataType;
    }
  }

  private getBoundaryConditionChartDataTypeDisplayName = (): string => {
    const dataType = this.getBoundaryConditionChartDataType();
    switch (dataType) {
      case BCDataType.Stage:
        return 'Simulated Stage';
      case BCDataType.Flow:
        return 'Simulated Discharge';
      default:
        return dataType;
    }
  }

  private areBoundaryConditionsEqual = (a: BCChartData, b: BCChartData): boolean => {
    if (a === b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    if (a.dataType !== b.dataType) {
      return false;
    }
    if (!this.areArraysEqual(a.times, b.times)) {
      return false;
    }
    if (!this.areArraysEqual(a.values, b.values)) {
      return false;
    }
    return true;
  }

  private areArraysEqual = (a: number[], b: number[]): boolean => {
    if (a === b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}
