import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import Auth from '@aws-amplify/auth';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const protocol = window.location.protocol;
    const host = window.location.host;
    if (
      request.url.startsWith(`${protocol}//${host}/api/`) ||
      request.url.startsWith(`/api/`) ||
      request.url.startsWith(`api/`)
    ) {
      return from(Auth.currentSession()).pipe(
        switchMap((session) => {
          const token = session.getIdToken().getJwtToken();
          request = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token,
            },
          });
          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
