import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { ChartConfiguration } from 'c3';
import { HttpClient } from '@angular/common/http';

interface Response {
  getGARRProjectDetails?;
  getHyetograph?;
  getGARRHyetographId?;
  getNWSHyetograph?;
  getPrevieuxHyetograph?;
}
@Injectable({ providedIn: 'root' })
export class HyetographService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getMosaics(project: string): Observable<string[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query GARR($project: String!) {
            getGARRProjectDetails(project: $project) {
              mosaics {
                symbolicName
              }
            }
          }
        `,
        variables: { project },
      })
      .pipe(
        map((res) =>
          res.data.getGARRProjectDetails.mosaics.map((m) => m.symbolicName)
        )
      );
  }

  getNWSHyetograph(input): Promise<any> {
    return firstValueFrom(this.apollo
      .query<Response>({
        query: gql`
          query NWS($input: NWSHyetographInput!) {
            getNWSHyetograph(input: $input) {
              millis
              incremental
              cumulative
            }
          }
        `,
        variables: { input },
      })
      .pipe(
        map((res) => {
          const data = res.data.getNWSHyetograph.map((d) => ({
            millis: d.millis,
            incremental: d.incremental,
            cumulative: d.cumulative,
          }));

          return {
            gaugeHyetograph: null,
            intersectingHyetograph: null,
            json: data,
            maxIncremental: Math.max(...data.map((d) => d.incremental)),
            maxCumulative: Math.max(...data.map((d) => d.cumulative)),
          };
        })
      ));
  }

  getPrevieuxHyetograph(input): Promise<any> {
    return firstValueFrom(this.apollo
      .query<Response>({
        query: gql`
          query Previeux($input: PrevieuxHyetographInput!) {
            getPrevieuxHyetograph(input: $input) {
              millis
              incremental
              cumulative
            }
          }
        `,
        variables: { input },
      })
      .pipe(
        map((res) => {
          const data = res.data.getPrevieuxHyetograph.map((d) => ({
            millis: d.millis,
            incremental: d.incremental,
            cumulative: d.cumulative,
          }));

          return {
            gaugeHyetograph: null,
            intersectingHyetograph: null,
            json: data,
            maxIncremental: Math.max(...data.map((d) => d.incremental)),
            maxCumulative: Math.max(...data.map((d) => d.cumulative)),
          };
        })
      ));
  }

  getGarrHyetograph(
    project: string,
    mosaicLayer: string,
    id: string,
    start: number,
    end: number
  ): Promise<any> {
    return firstValueFrom(this.apollo
      .query<Response>({
        query: gql`
          query GARR(
            $project: String!
            $mosaicLayer: String!
            $id: String!
            $start: Long!
            $end: Long!
          ) {
            getGARRHyetographId(
              project: $project
              mosaicLayer: $mosaicLayer
              id: $id
              start: $start
              end: $end
            ) {
              millis
              incremental
              cumulative
            }
          }
        `,
        variables: { project, mosaicLayer, id, start, end },
      })
      .pipe(
        map((res) => {
          const data = res.data.getGARRHyetographId.map((d) => ({
            millis: d.millis,
            iIncremental: d.incremental,
            iCumulative: d.cumulative,
          }));

          return {
            gaugeHyetograph: null,
            json: data,
            maxIncremental: Math.max(...data.map((d) => d.iIncremental)),
            maxCumulative: Math.max(...data.map((d) => d.iCumulative)),
          };
        })
      ));
  }

  getSMHydrograph(
    project: string,
    layer: string,
    id: string,
    start: number,
    end: number,
  ): Promise<any> {
    return firstValueFrom(this.http
      .get(`api/${project}/soilmoisture/layer/${layer}/range/${start}/${end}/bin/${encodeURIComponent(id)}/graph`)
      .pipe(
        map((res: any) => res.map((d) => ({
            millis: d.time,
            soilMoisture: d.value,
          })))
      ));
  }

  getHyetograph(
    project: string,
    start: number,
    end: number,
    id: string,
    mosaicId?: string
  ): Promise<ApolloQueryResult<Response>> {
    return firstValueFrom(this.http
      .get(
        `api/${project}/rainvieux/gaugedata/range/${start}/${end}/gauge/${id}/hyetograph.json?intersectionLayer=${mosaicId}`
      )
      .pipe(
        map((data: any) => {
          const gaugeDataObj = {};
          const gauges = data.gaugeHyetograph.map((g) => {
            g.cumulative = this.getValue(g.cumulative);
            g.incremental = this.getValue(g.incremental);
            gaugeDataObj['m' + g.millis] = g;
            delete g.__typename;
            return g;
          });

          let maxIncremental = gauges.reduce(
            (a, b) => Math.max(a, b.incremental),
            0
          );
          let maxCumulative = gauges.reduce(
            (a, b) => Math.max(a, b.cumulative),
            0
          );
          const intersectDataObj = {}
          if (data.intersectingHyetograph !== null) {
            const intersects = data.intersectingHyetograph.map((intersect) => {
              const iCumulative = this.getValue(intersect.cumulative);
              const iIncremental = this.getValue(intersect.incremental);
              const millis = intersect.millis;
              if (intersectDataObj['m' + intersect.millis]) {
                intersectDataObj['m' + intersect.millis].iCumulative = iCumulative;
                intersectDataObj['m' + intersect.millis].iIncremental = iIncremental;
              } else {
                intersectDataObj['m' + intersect.millis] = {
                  iIncremental,
                  iCumulative,
                  millis,
                };
              }
              return intersect;
            });
            maxIncremental = intersects.reduce(
              (a, b) => Math.max(a, b.incremental),
              maxIncremental
            );
            maxCumulative = intersects.reduce(
              (a, b) => Math.max(a, b.cumulative),
              maxCumulative
            );
          }
          data.json = {
            gauge: Object.keys(gaugeDataObj)
              .map((key) => gaugeDataObj[key])
              .sort((a, b) => a.millis - b.millis),
            intersect: Object.keys(intersectDataObj)
            .map((key) => intersectDataObj[key])
            .sort((a, b) => a.millis - b.millis)
          }
          data.maxIncremental = this.getValue(maxIncremental);
          data.maxCumulative = this.getValue(maxCumulative);

          return data;
        })
      ));
  }

  getValue(num: number): number {
    const multiplier = Math.pow(10, 3);
    if (num < 0 || num === null) {
      return null;
    } else {
      return Math.round(num * multiplier) / multiplier;
    }
  }

  getChartSettings(config, unitAbbr = 'in'): ChartConfiguration {
    return {
      bindto: config.bindto,
      data: {
        json: [],
        axes: {
          cumulative: 'y',
          incremental: 'y2',
          iCumulative: 'y',
          iIncremental: 'y2',
        }
      },
      bar: { width: config.barWidth },
      point: { r: 2 },
      transition: { duration: null },
      padding: {
        right: 52,
        bottom: 8,
        left: 52,
      },
      tooltip: {
        format: {
          title: (d): string =>
            moment.tz(d, config.timezone).format('YYYY/MM/DD HH:mm'),
          value: (d: number): string => d + ' ' + unitAbbr,
        },
      },
      axis: {
        x: {
          type: 'timeseries',
          height: 52,
          tick: {
            fit: false,
            // culling: { max: Math.round((config.elWidth - 100) / 44) }, not working...
            format: (d): string =>
              moment.tz(d, config.timezone).format('MM/DD HH:mm'),
            // width: 40, for multiline when not rotated
            rotate: -45
          },
        },
        y: {
          tick: {
            format: (d): string => d.toFixed(d < 10 ? 2 : d < 100 ? 1 : 0),
          },
          padding: {
            top: 2,
            bottom: 2,
          },
          label: {
            text: `cumulative (${unitAbbr})`,
            position: 'outer-middle',
          },
        },
        y2: {
          show: true,
          tick: {
            format: (d): string => d.toFixed(d < 10 ? 2 : d < 100 ? 1 : 0),
          },
          padding: {
            top: 5,
            bottom: 0,
          },
          label: {
            text: `incremental (${unitAbbr})`,
            position: 'outer-middle',
          },
          inverted: true,
        },
      },
    };
  }

  getSMChartSettings(config): ChartConfiguration {
    return {
      bindto: config.bindto,
      data: {
        json: [],
        axes: {
          soilMoisture: 'y',
        }
      },
      bar: { width: config.barWidth },
      point: { r: 2 },
      line: { connectNull: true },
      transition: { duration: null },
      padding: {
        right: 52,
        bottom: 8,
        left: 52,
      },
      tooltip: {
        format: {
          title: (d): string =>
            moment.tz(d, config.timezone).format('YYYY/MM/DD HH:mm'),
          value: (d: number): string => d.toString(),
        },
      },
      axis: {
        x: {
          type: 'timeseries',
          height: 52,
          tick: {
            fit: false,
            format: (d): string =>
              moment.tz(d, config.timezone).format('MM/DD HH:mm'),
            rotate: -45
          },
        },
        y: {
          tick: {
            format: (d): string => d.toFixed(d < 10 ? 2 : d < 100 ? 1 : 0),
          },
          padding: {
            top: 2,
            bottom: 2,
          },
          label: {
            text: `Soil Moisture Level`,
            position: 'outer-middle',
          },
        },
      },
    };
  }
}
