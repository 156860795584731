import { Apollo, gql } from 'apollo-angular';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { S3ProviderPutConfig, Storage } from '@aws-amplify/storage';
import { User } from '@app/shared/models';
import { UserService } from './user.service';

interface Response {
  myUser?: User;
}

@Injectable()
export class AuthService {
  public pictureChange$: EventEmitter<any> = new EventEmitter();

  constructor(private apollo: Apollo, private userService: UserService) {}

  updateUser(changes: any): Observable<any> {
    return this.apollo.query<Response>({
      query: gql`
        query Query($input: updateUserInput!) {
          updateUser(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: { ...changes },
      },
    });
  }

  saveOtpCompleted() {
    localStorage.setItem(LATEST_OTP, JSON.stringify(Date.now()));
  }

  isOtpStepNeeded() {
    const lastOtpSignIn = localStorage.getItem(LATEST_OTP);
    if(lastOtpSignIn) {
      return (Date.now() - Number(lastOtpSignIn)) > MILLISECONDS_IN_24_HOURS;
    }
    return true;
  }

  /** signout */
  public signOut(): void {
    Auth.signOut().then(
      () => {
        localStorage.removeItem(LATEST_OTP);
        this.userService.removeCurrentProject();
        this.apollo.client.resetStore();
      },
      (error) => console.error(error)
    );
  }

  public getFile = (key: string, opts?): Promise<any> => Storage.get(key, opts);

  public updateProfilePicture(image: File, id): Observable<any> {
    const putConfig: S3ProviderPutConfig = {
      level: 'protected',
      contentType: 'image/png',
    };
    const imgStr = 'profile.png';

    return from(Storage.put(imgStr, image, putConfig)).pipe(
      mergeMap(() => this.updateUser({ id, picture: imgStr })),
      tap(() => this.pictureChange$.emit()),
      catchError((e) => of(e))
    );
  }
}

const LATEST_OTP = 'latest_otp';
const MILLISECONDS_IN_24_HOURS = 24 * 60 * 60 * 1000;
