import { UntypedFormGroup, ValidationErrors, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment-timezone';

export function range(fg: UntypedFormGroup): ValidationErrors {
  const ctrls = fg.value;
  if (ctrls.realtime || !ctrls.startDate || !ctrls.startTime ||
      !ctrls.endDate || !ctrls.endTime || !ctrls._timezone) {
    return null;
  }
  const start = moment.tz(
    `${ctrls.startDate.format('YYYY-MM-DD')}T${ctrls.startTime}`,
    ctrls._timezone
  );
  const end = moment.tz(
    `${ctrls.endDate.format('YYYY-MM-DD')}T${ctrls.endTime}`,
    ctrls._timezone
  );
  return start.isBefore(end) ? null : { range: true };
}

export function maximums(fg: UntypedFormGroup): ValidationErrors {
  const ctrls = fg.value;
  if (!ctrls.endDate || !ctrls.endTime || !ctrls._timezone) {
    return null;
  } else if (!ctrls._momentOnly && !ctrls.realtime) {
    if (!ctrls.startDate || !ctrls.startTime) {
      return null;
    }
  }
  const end = moment.tz(
    `${ctrls.endDate.format('YYYY-MM-DD')}T${ctrls.endTime}`,
    'YYYY-MM-DDTHH:mm', true, ctrls._timezone
  );
  const invalid = end.isAfter(ctrls.maxDate);
  // const invalidStart = start && start.isAfter(ctrls.maxDate);
  return invalid ? { maximums: true } : null;
}

export function duration(fg: UntypedFormGroup, numDays = 35): ValidationErrors {
  const ctrls = fg.value;
  if (ctrls.realtime || !ctrls.startDate || !ctrls.startTime ||
      !ctrls.endDate || !ctrls.endTime || !ctrls._timezone) {
    return null;
  }
  const start = moment.tz(
    `${ctrls.startDate.format('YYYY-MM-DD')}T${ctrls.startTime}`,
    ctrls._timezone
  );
  const end = moment.tz(
    `${ctrls.endDate.format('YYYY-MM-DD')}T${ctrls.endTime}`,
    ctrls._timezone
  );
  const duration = end.diff(start, 'days');
  return duration > numDays ? { duration: true } : null;
}

export function incompleteDate(fc: UntypedFormControl): ValidationErrors {
  return fc.value && fc.value.isValid() ? null : { incompleteDate: true };
}

export function incompleteTime(fc: UntypedFormControl): ValidationErrors {
  return fc.value && moment(fc.value, 'HH:mm', true).isValid() ? null : { incompleteTime: true };
}