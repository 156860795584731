import { NgModule } from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { split, InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { defaultDataIdFromObject } from '@apollo/client/core';

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        // Create an http link:
        const http = httpLink.create({
          uri: '/api/graphql',
        });

        // Create a WebSocket link:
        const protocol = window.location.protocol.replace('http', 'ws');
        const host = window.location.host;
        const ws = new WebSocketLink({
          uri: `${protocol}//${host}/ws/api/endpoint`,
          options: {
            timeout: 40000,
            reconnect: true,
            reconnectionAttempts: 2,
            lazy: true
          },
        });

        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // split based on operation type
          ({query}) => {
            const {kind, operation} = getMainDefinition(query) as any;
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          ws,
          http,
        );

        return {
          link,
          cache: new InMemoryCache({
            dataIdFromObject: (object: any): string => {
              switch (object.__typename) {
                case 'DataLayer': return object.id + object.tableId;
                default: return defaultDataIdFromObject(object);
              }
            }
          }),
          defaultOptions: {
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          }
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
