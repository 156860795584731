<div class="chart-head">
  <h3>{{ name }}</h3>
  <span> {{ displayId }} {{ feed ? ' | ' + feed : '' }} </span>
</div>
<div [ngSwitch]="type" class="chart-wrapper">
  <app-hydrograph
    *ngSwitchCase="'hydrograph'"
    [groupTooltip]="true"
    [id]="id"
    [times]="times"
    [attributes]="attributes"
    [lngLat]="lngLat"
    [update]="timestamp"
  ></app-hydrograph>
  <app-hyetograph
    *ngSwitchCase="'hyetograph'"
    [attributes]="attributes"
    [times]="times"
    [mosaicId]="symbolicName"
    [id]="id"
    [update]="timestamp"
  ></app-hyetograph>
</div>
