import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Time } from '@app/shared/models';
import { LngLat } from 'mapbox-gl';

interface UrlConfig {
  type: string;
  id: string;
  name: string;
  feed: string;
  displayId: string;
  attributes: string[];
  time: Time;
  symbolicName: string;
  lngLat?: LngLat;
}

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  public readonly chartTypes = ['hydrograph', 'hyetograph'];
  constructor(private router: Router) {}

  getUrl({
    type,
    id: p,
    name: n,
    feed: f,
    displayId: id,
    attributes,
    time,
    symbolicName: sym,
    lngLat,
  }: UrlConfig): string {
    const ty = this.chartTypes.indexOf(type);
    const as = attributes.join(',') || null;
    const r = `${time.start.getTime()},${time.end.getTime()}${
      time.realtime ? ',1' : ''
    }`;
    const queryParams = { ty, p, n, f, id, sym, as, r };

    if (ty !== -1) {
      queryParams['l'] = lngLat.lng.toFixed(4) + ',' + lngLat.lat.toFixed(4);
    }

    return this.router.serializeUrl(
      this.router.createUrlTree([`/graph`], { queryParams })
    );
  }
}
