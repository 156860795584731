<mat-grid-list cols="3" rowHeight="80" *ngIf="products.length">
  <mat-grid-tile *ngFor="let prod of products">
    <a  class="no-style app-button" 
        [class.disabled]="router.url.includes(prod.link)"
        [title]="prod.title"
        [routerLink]="prod.link">
      <mat-icon inline
          [svgIcon]="prod.svg ? prod.icon : null">
        {{prod.svg ? '' : prod.icon}}
      </mat-icon>
      <div class="title">{{prod.title}}</div>
    </a>
  </mat-grid-tile>
</mat-grid-list>
<div *ngIf="!products.length">
  <span class="error" [hidden]="loading">No apps listed</span>
  <span [hidden]="!loading">Loading apps...</span>
</div>
