export class Hydroplot {
  displayName: string;
  symbolicName: string;
  location: string;
  zone: string;
  elevationDatum: string;
  timeBack: bigint;
  timeForward: bigint;
  intervalMinutes?: number;
  pointData: Array<any>;
}
export default Hydroplot;
