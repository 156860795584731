import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';
  DATE_FORMAT = 'yyyy/MM/dd';
  TIME_FORMAT = 'HH:mm';
  PROFILE_IMG = '/assets/images/logo_gray-256x256.png';
  PERIODS = [ 5, 15, 30, 60, 3 * 60, 6 * 60, 12 * 60, 24 * 60, 48 * 60, 72 * 60,
    120 * 60, 168 * 60 ];
  MAPS = {
    roles: [
      {
        id: 0,
        name: 'VAI Administrator',
        shortname: 'VAI Admin',
        type: 'admin'
      }, {
        id: 1,
        name: 'Company Administrator',
        shortname: 'Admin',
        type: 'admin'
      }, {
        id: 2,
        name: 'Company User',
        shortname: 'User',
        type: 'user'
      }, {
        id: 3,
        name: 'Guest',
        shortname: 'Guest',
        type: 'user'
      }
    ]
  };
}
