import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NavbarModule } from './navbar/navbar.module';
import { PageNotFoundComponent } from './page-not-found.component';
import { ActionsDialogComponent } from './actions-dialog/actions-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    NavbarModule
  ],
  declarations: [
    PageNotFoundComponent,
    ActionsDialogComponent
  ],
  exports: [
    NavbarModule,
    PageNotFoundComponent,
    ActionsDialogComponent,
    FormsModule
  ]
})
export class SharedModule { }
