<div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="container" fxLayout="row" fxLayoutAlign="center center" style="justify-content: space-between;">
    <div></div>
    <div></div>
    <div><h2>{{chartTitle}}</h2></div>
    <div></div>
  </div>
  <div>
    <button mat-icon-button (click)="pdfClick($event)"><mat-icon>print</mat-icon></button>
    <!-- <button mat-button name="settings" *ngIf="!hide" (click)="settingsClick()"><mat-icon>settings</mat-icon></button> -->
    <button mat-icon-button (click)="settingsClick()"><mat-icon>settings</mat-icon></button>
  </div>
  <div *ngIf="start">
    <strong>Range: {{start}} - {{end}}</strong>
  </div>
</div>

<div  class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="justify-content: space-between">
  <div class="flex-item" fxFlex="80%">
    <div class="spinner" *ngIf="!ready" fxLayoutAlign="center center">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <div *ngIf="ready" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
      <canvas baseChart #baseChart="base-chart" id="chart"
                  [datasets]="data"
                  [labels]="ticks"
                  [options]="lineChartOptions"
                  [legend]="true"
                  [colors]="filteredLineChartColors"
                  [chartType]="lineChartType"
                  (chartClick)="chartClicked($event)">
      </canvas>
    </div>
  </div>
</div>
