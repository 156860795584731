import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AccessManagerService } from '@app/features/access-manager';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Organization } from '@app/shared/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Globals } from '@app/globals';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  isLoadingResults = true;
  dataSource: MatTableDataSource<Organization>;
  displayedColumns = ['name', 'projects', 'groups', 'users'];
  selectedRow: Organization;
  rxSubs = new Subscription();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private amService: AccessManagerService,
    public globals: Globals
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property): number => {
      switch (property) {
        case 'projects.length': return item.projects.length;
        default: return item[property];
      }
    };
    this.rxSubs.add(
      this.amService.getOrganizations()
        .subscribe(
          (cos: Organization[]) => {
            this.dataSource.data = cos;
            this.isLoadingResults = false;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.rxSubs.unsubscribe();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateTable(change): void {
    let data: any[] = this.dataSource.data;
    switch (change.type) {
      case 'create':
        data.push(change.data);
        break;
      case 'update':
        data = data.map(
          el => change.data.id === el.id ? change.data : el
        );
        break;
      case 'delete':
        data = data.filter(el => change.data.id !== el.id);
        break;
    }
    this.dataSource = new MatTableDataSource(data);

    // updates cache
    this.amService.getOrganizations().subscribe(
      (cos: Organization[]) => this.dataSource = new MatTableDataSource(cos)
    );
  }

  selectOrganization(row): void {
    this.selectedRow = row;
  }

}
