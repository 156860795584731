import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { OrganizationsComponent } from './organizations/organizations.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full'
  }, {
    path: 'users',
    component: UsersComponent,
    data: { title: 'Access Manager: Users' }
  }, {
    path: 'organizations',
    component: OrganizationsComponent,
    data: { title: 'Access Manager: Organizations' }
  }, {
    path: 'organization',
    component: OrganizationsComponent,
    data: { title: 'Access Manager: Organization' }
  }, {
    path: 'companies',
    redirectTo: 'organizations',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AccessManagerRoutingModule { }
