import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Project, Time } from '@app/shared/models';
import { TimepickerService } from '@app/shared/timepicker/timepicker.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  @Input() project: Project;
  alertsSub: Subscription;
  times: Time;
  alertCheck = Date.now();
  alertLatest = 0;

  constructor(
    private timeService: TimepickerService,
    private _snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.alertsSub = this.timeService.alerts$.subscribe((a) => {
      const end = new Date(a.timestamp);
      const start = new Date(end.getTime() - 24 * 3.6e6);

      if (!!this.alertLatest && a.timestamp > this.alertLatest) {
        this._snack.open('You have new alerts.', null, {
          panelClass: 'error',
          duration: 6000,
        });
      }

      this.times = { start, end };
      this.alertLatest = a.timestamp;
    });
  }

  ngOnDestroy(): void {
    this.alertsSub.unsubscribe();
  }

  onClickAlerts = (): void => {
    this.alertCheck = Date.now();
  };
}
