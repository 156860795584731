import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { NavbarComponent } from './navbar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../avatar/avatar.module';
import { NavGridModule } from '../nav-grid/nav-grid.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NotificationsElementModule } from '@app/features/notifications/element/notifications-element.module';
import { AlertsComponent } from './alerts/alerts.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    NavGridModule,
    AvatarModule,
    NotificationsElementModule,
    HttpClientModule,

    MatGridListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule,
  ],
  declarations: [
    NavbarComponent,
    UserMenuComponent,
    AlertsComponent
  ],
  exports: [
    NavbarComponent
  ]
})
export class NavbarModule { }
