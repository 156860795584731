<div class="organizations-container" fxFill fxLayout="column">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner diameter="80"></mat-spinner>
  </div>

  <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="table-tools-container">
    <mat-form-field fxFlex="100" fxFlex.gt-sm="60" fxFlex.gt-md="40" fxFlex.gt-lg="25">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      <mat-icon matSuffix>filter_list</mat-icon>
    </mat-form-field>
    <div class="tools-button-container" fxFlex fxLayoutAlign="end center" fxLayoutGap="16px">
    </div>
  </div>

  <div class="table-container">
    <table mat-table class="table" [dataSource]="dataSource" multiTemplateDataRows matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
        <td mat-cell *matCellDef="let row">
          {{row.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="projects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Projects</th>
        <td mat-cell *matCellDef="let row">
          {{row.projects.length}}
        </td>
      </ng-container>

      <ng-container matColumnDef="groups">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Groups</th>
        <td mat-cell *matCellDef="let row">
          {{row.groups.length}}
        </td>
      </ng-container>

      <ng-container matColumnDef="users">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Users</th>
        <td mat-cell *matCellDef="let row">
          {{row.users.length}}
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail"
               [@detailExpand]="dataSource?.data.length === 1 || element == selectedRow ? 'expanded' : 'collapsed'">
            <app-organization [data]="element" class="organization-info"></app-organization>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
          class="expandable-row"
          *matRowDef="let row; columns: displayedColumns"
          [class.expanded-row]="selectedRow === row"
          (click)="selectedRow === row ? selectedRow = {} : selectedRow = row"
          ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </div>

  <mat-paginator [length]="dataSource.data.length"
      [pageSize]="25"
      [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>