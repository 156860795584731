import { Project, User, Group } from '@app/shared/models';

export class Organization {
  id?: number;
  name?: string;
  description?: string;
  logo?: string;
  projects?: Project[];
  users?: User[];
  groups?: Group[];

  roleCounts = function(): string {
    const admins = this.users.filter(u => u.role < 2).length + ' admin(s)';
    const users = this.users.filter(u => u.role > 1).length + ' user(s)';
    return `${admins} & ${users}`;
  };
}
