import {Apollo, gql} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Hydroplot} from '@app/shared/models/hydroplotter/hydroplot.model';



import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class DataService {
  results: any;

  constructor( private apollo: Apollo ) { }

  getData(project: string, start: number, end: number, timeZone: string): Observable<Hydroplot> {
    return this.apollo
      .query<Response>({
        query: gql`query Hydroplotter($input:HydroplotterInput)
        {
          getHydroplot(input:$input)
          {
          displayName,
          pointData
            {
            label,
            pointData
              {
              id,
              description,
               seriesData
                {
                typeName,
                 seriesData
                  {
                    type,
                    seriesData
                      {
                      displayName,
                      aggregateDataSeries
                          {
                        label,
                        aggregateDataSeries
                          {
                          interval,
                          data
                            {
                            times,
                            values
                            }
                          }
                        }
                      }
                  }
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            timeZone: timeZone,
            start: start,
            end: end,
            project: project
          }
        }
      })
      .pipe(
        map(
          (res) => {
            const hydroplot = {
              ...res.data.getHydroplot
            };
            return Object.assign(new Hydroplot(), hydroplot);
          }
        )
      );
  }
}

interface ApiResponse {
  displayName: string;
  pointData: Array<any>;
}

interface Response {
  getHydroplot?: Hydroplot;
}
