import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MediaObserver } from 'ngx-flexible-layout';
import {
  NewUserComponent,
  UserDetailComponent,
  AccessManagerService,
  CloseData
} from '@app/features/access-manager';
import { formatDate } from '@angular/common';
import { User, Project } from '../../../shared/models';
import { Globals } from '@app/globals';
import { firstValueFrom, Subscription } from 'rxjs';
import { UserService } from '@app/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  private rxSubs = new Subscription();
  columns = [
    {
      def: 'firstName',
      name: 'First Name'
    }, {
      def: 'lastName',
      name: 'Last Name'
    },
    { def: 'role', name: 'Role' },
    {
      def: 'groups',
      name: 'Groups',
      media: 'gt-sm',
      truncate: true
    },
    {
      def: 'company.name',
      name: 'Organization',
      media: 'gt-xs',
      roleGuard: 0
    },
    {
      def: 'lastAccessed',
      name: 'Last Accessed',
      media: 'gt-xs',
      roleGuard: 1 // roles 1 and below will see this
    }
  ];
  project: Project;
  mediaWatcher: Subscription;
  activeMediaQuery = '';
  displayedColumns = this.columns.map(x => x.def);
  dataSource: MatTableDataSource<User>;
  loading = true;
  selectedRow: any;
  error = false;
  isMobile = true;
  count = 0;
  private user: User;
  isAdmin = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(
      private amService: AccessManagerService,
      public dialog: MatDialog,
      public globals: Globals,
      private mediaObserver: MediaObserver,
      private userService: UserService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    firstValueFrom(this.amService.getUsers()).then(
      (users: User[]) => {
        this.dataSource.data = users;
        this.loading = false;
        this.error = false;
      },
      (err) => {
        this.loading = false;
        this.error = err.exception ? err.exception.message : (err.message || err);
      }
    );

    this.rxSubs.add(
      this.userService.currentUser.subscribe(user => {
        if (user) {
          this.user = user;
          this.isAdmin = user.isAdmin();
          this.dataSource.filterPredicate = (data: User, filter: string): boolean => {
            const str = (data.firstName + data.lastName +
              (user.role < 1 ? data.company.name : '') +
              this.globals.MAPS.roles[data.role].shortname +
              data.groupsToString()).toLowerCase();

            return !filter || str.includes(filter.toLowerCase());
          };
        }
      }));
    this.rxSubs.add(
      this.userService.project$.subscribe(proj => {
        const initialized = !!this.project;
        this.project = proj;
        if (initialized) {
          this.table.renderRows();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.rxSubs.unsubscribe();
  }

  getProperty(obj, path): string {
    const prop = path.split('.').reduce((o, p) => o && o[p], obj);
    switch (path) {
      case 'lastAccessed':
        const offset = this.project && prop ? this.project.getOffset(prop) : null;
        return prop ?
          formatDate(prop, this.globals.DATETIME_FORMAT, 'en', offset) : 'N/A';
      case 'groups': {
        if (prop.length) {
          const groupList = prop.map(g => g.name);
          return groupList.join("; ");
        } else {
          return 'None';
        }
      }
      case 'role':
        return this.globals.MAPS.roles[prop].shortname;
      default:
        return prop === null ? 'N/A' : prop;
    }
  }

  getDisplayedColumns(): any[] {
    const role = this.user ? this.user.role : 3;
    const hasGroups = this.user ? this.user.company.groups.length : false;
    return this.columns
      .filter(col => (!col.media || this.mediaObserver.isActive(col.media)) &&
        (col.roleGuard === undefined || role <= col.roleGuard) &&
        (col.def !== 'groups' || hasGroups || role === 0)
      ).map(col => col.def);
  }

  groupsToString(groups): string {
    return groups.map(g => g.name).join(', ');
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDetailDialog(user): void {
    this.selectedRow = user;
    const dialogRef = this.dialog.open(UserDetailComponent, {
      minWidth: '360px',
      maxWidth: 'calc(100% - 16px)',
      maxHeight: 'calc(100% - 16px)',
      data: user,
      disableClose: true
    });

    dialogRef.beforeClosed()
      .subscribe(this.changeList);

    dialogRef.afterClosed()
      .subscribe(() => this.selectedRow = {});
  }

  openNewUserDialog(): void {
    const dialogRef = this.dialog.open(NewUserComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.beforeClosed().subscribe(this.changeList);
  }

  changeList = (result: CloseData): void => {
    if (result && result.type) {
      let newData = this.dataSource.data;
      switch (result.type) {
        case 'remove':
          newData = newData.filter(el => result.data.id !== el.id);
          break;
        case 'edit':
          newData = newData.map(
            el => result.data.id === el.id ? result.data : {...result.data, ...el}
          );
          break;
        case 'new':
          newData.push(result.data);
          break;
      }

      this.dataSource.data = newData;
    }
  }
}
