<form
  [formGroup]="changePasswordForm"
  (ngSubmit)="changePassword(changePasswordForm)"
  class="form-box"
>
  <h2>Change Password</h2>
  <mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Enter Current Password</mat-label>
      <input matInput type="password" formControlName="currentPassword" />
      <mat-error
        *ngIf="nf.currentPassword.touched && nf.currentPassword.invalid"
      >
        <mat-error *ngIf="nf.currentPassword.errors.required"
          >Required</mat-error
        >
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Enter New Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="password"
        [type]="hidePassword ? 'password' : 'text'"
      />
      <mat-icon
        class="cursor-style"
        matSuffix
        (click)="hidePassword = !hidePassword"
        >{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
      >
      <mat-error *ngIf="nf.password.touched && nf.password.invalid">
        <mat-error *ngIf="nf.password.errors.required">Required</mat-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="confirmPassword"
        [type]="hide ? 'password' : 'text'"
      />
      <mat-icon class="cursor-style" matSuffix (click)="hide = !hide">{{
        hide ? 'visibility_off' : 'visibility'
      }}</mat-icon>
      <mat-error
        *ngIf="nf.confirmPassword.touched && nf.confirmPassword.invalid"
      >
        <mat-error *ngIf="nf.confirmPassword.errors.required"
          >Required</mat-error
        >
        <mat-error *ngIf="nf.confirmPassword.errors.confirmedValidator"
          >Password and Confirm Password must be match.</mat-error
        >
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      color="primary"
      cdkFocusInitial
      [disabled]="changePasswordForm.invalid"
      type="submit"
    >
      Change Password
    </button>
  </mat-dialog-actions>
</form>
