import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HydroplotterComponent } from './hydroplotter.component';

const routes: Routes = [
  {
    path: '',
    component: HydroplotterComponent,
    data: { title: 'Hydroplotter' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
