import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, Organization, Group } from '@app/shared/models';

interface Response {
  myUser?: User;
  allUsers?: User[];
  allCompanies?: Organization[];
  allGroups?: Group[];
  getUser?: User;
  getCompany?: Organization;
  changeUser?: any;
  createUser?: User;
  createGroup?: Group;
  getGroup?: Group;
  updateUser?: User;
}

@Injectable({ providedIn: 'root' })
export class AccessManagerService {
  constructor(private apollo: Apollo) {}

  getUsers(): Observable<User[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query {
            allUsers {
              id
              firstName
              lastName
              role
              phone
              lastAccessed
              company {
                id
                name
              }
              groups {
                id
                name
              }
            }
          }
        `,
      })
      .pipe(map((res) => res.data.allUsers.map(this.createUser)));
  }

  getUserDetail(userId: number): Observable<User> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($id: Int!) {
            getUser(id: $id) {
              username
              phone
              email
              company {
                groups {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          id: userId,
        },
      })
      .pipe(
        map((res) => {
          const user = {
            id: userId,
            ...res.data.getUser,
            company: {
              groups: res.data.getUser.company.groups,
            },
          };
          return this.createUser(user);
        })
      );
  }

  getOrganizations(): Observable<Organization[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query {
            allCompanies {
              id
              name
              description
              groups {
                id
                name
              }
              logo
              projects {
                id
                name
                timezone
                location
                interval
                isNRT
              }
              groups {
                id
                name
              }
              users {
                role
              }
            }
          }
        `,
      })
      .pipe(
        map((res) =>
          res.data.allCompanies.map((company: any) =>
            Object.assign(new Organization(), company)
          )
        )
      );
  }

  // for new user form
  newUserFormInfo(): Observable<Organization[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query {
            allCompanies {
              id
              name
              groups {
                id
                name
              }
            }
          }
        `,
      })
      .pipe(
        map((res) =>
          res.data.allCompanies.map((company: any) =>
            Object.assign(new Organization(), company)
          )
        )
      );
  }

  inviteUser(attributes: any): Observable<any> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($input: createUserInput!) {
            createUser(input: $input) {
              id
              lastAccessed
              groups {
                id
                name
              }
            }
          }
        `,
        variables: {
          input: { ...attributes },
        },
      })
      .pipe(
        map((res) => {
          if (res.data.createUser) {
            return this.createUser(res.data.createUser);
          }
          throw new Error(
            res.errors
              .map((e) => (e['exception'] ? e['exception'].message : e.message))
              .join('; ')
          );
        })
      );
  }

  private createUser(user): User {
    return Object.assign(new User(), {
      ...user,
      phone: user.phone === 'null' || user.phone === null ? '' : user.phone,
    });
  }

  updateUser(changes: any): Observable<any> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($input: updateUserInput!) {
            updateUser(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: { ...changes },
        },
      })
      .pipe(map((res) => res.data.updateUser));
  }

  removeUser(id: number): Observable<any> {
    return this.apollo.query<Response>({
      errorPolicy: 'none',
      query: gql`
        query Query($id: Int!) {
          deleteUser(id: $id)
        }
      `,
      variables: {
        id: id,
      },
    });
  }

  getGroups(id: number): Observable<any> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($id: Int!) {
            getCompany(id: $id) {
              groups {
                id
                name
              }
            }
          }
        `,
        variables: { id },
      })
      .pipe(map((res) => res.data.getCompany.groups));
  }

  getGroup(id: number): Observable<any> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($id: Int!) {
            getGroup(id: $id) {
              id
              name
              description
              permissions
              users {
                role
              }
            }
          }
        `,
        variables: { id },
      })
      .pipe(map((res) => Object.assign(new Group(), res.data.getGroup)));
  }

  createGroup(companyId: number, name: string, description?: string) {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($input: createGroupInput!) {
            createGroup(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: { companyId, name, description },
        },
      })
      .pipe(map((res) => res.data.createGroup.id));
  }

  deleteGroup(id: number) {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($id: Int!) {
            deleteGroup(id: $id)
          }
        `,
        errorPolicy: 'none',
        variables: { id },
      })
      .pipe();
  }

  updateGroup(changes: any): Observable<any> {
    return this.apollo.query<Response>({
      errorPolicy: 'none',
      query: gql`
        query Query($input: updateGroupInput!) {
          updateGroup(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: { ...changes },
      },
    });
  }
}
