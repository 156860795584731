import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, UserService } from '@app/core';
import { Globals } from '@app/globals';
import { PictureDialogComponent } from './picture-dialog/picture-dialog.component';
import { MediaObserver } from 'ngx-flexible-layout';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: any;
  picture: any;
  error: any;
  rxSub = new Subscription();

  constructor(
    public dialog: MatDialog,
    public media: MediaObserver,
    private auth: AuthService,
    private userService: UserService,
    private globals: Globals
  ) {}

  ngOnInit(): void {
    this.userService.currentUser.pipe(take(1)).subscribe(this.updateUser);
  }

  updateUser = (user): void => {
    this.user = user;
    this.updatePicture();
  };

  choosePicture(): void {
    const element = document.getElementById('upload_picture');
    element.click();
  }

  openPictureDialog(event: any): void {
    const pictureDialog = this.dialog.open(PictureDialogComponent, {
      width: '340px',
      data: { event: event },
    });

    pictureDialog.afterOpened().subscribe(() => (event.srcElement.value = ''));

    pictureDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.user.picture = result;
        this.updatePicture();
        event.srcElement.value = '';
      }
    });
  }

  updatePicture(): void {
    const filename = this.user.picture;
    if (filename) {
      this.auth
        .getFile(filename, { level: 'protected' })
        .then((url) => (this.picture = url));
    } else {
      this.picture = this.globals.PROFILE_IMG;
    }
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent, {
      width: '500px',
    });
  }
}
