import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '../notifications.service';
import { GenericAlert, TypeStyles } from '../notifications.models';
import { Project, Time } from '@app/shared/models';
import { forkJoin, Subject } from 'rxjs';
import { RuleType } from '@app/features/alert-manager/alert-manager.models';
import {
  FillLayer,
  LineLayer,
  Map,
  PaddingOptions,
  SymbolLayer,
} from 'mapbox-gl';
import { MatDialog } from '@angular/material/dialog';
import * as extent from '@mapbox/geojson-extent';
import * as moment from 'moment-timezone';
import { TimepickerService } from '@app/shared/timepicker/timepicker.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-notifications-element',
  templateUrl: './notifications-element.component.html',
  styleUrls: ['./notifications-element.component.scss'],
})
export class NotificationsElementComponent implements OnChanges, OnDestroy {
  alerts: GenericAlert[];
  typeStyles: TypeStyles;
  loading = true;
  error = false;
  selected: GenericAlert;
  outlineId: string;
  destroy$ = new Subject();
  private _hasMap = false;
  get hasMap(): boolean {
    return this._hasMap;
  }
  @Input()
  set hasMap(value: boolean) {
    this._hasMap = coerceBooleanProperty(value);
  }
  @Input() mapgl: Map;
  @Input() project: Project;
  @Input() times: Time;
  @Input() mapPadding: PaddingOptions;
  @Input() dense = false;
  @ViewChild('detailsDialog', { static: true }) detailsDialog: TemplateRef<any>;

  constructor(
    private service: NotificationsService,
    public dialog: MatDialog,
    private timesService: TimepickerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project && changes.project.currentValue) {
      this.onProjectChange();
      if (!changes.project.isFirstChange()) {
        this.ngOnDestroy();
      }
    }
    if (changes.times && changes.times.currentValue) {
      this.onTimesChange(changes.times);
    }
  }

  onTimesChange = ({ previousValue }: SimpleChange): void => {
    if (!this.times.realtime) {
      this.destroy$.next(null);
      this.updateAlerts(this.times);
    } else if (!previousValue?.realtime) {
      this.timesService.alerts$
        .pipe(
          map((a) => {
            let end = new Date(a.timestamp);
            if (this.times.end.getTime() > a.timestamp) {
              end = this.times.end;
            }
            return {
              start: this.hasMap
                ? this.times.start
                : new Date(a.timestamp - 24 * 60 * 60 * 1000),
              end,
              realtime: true,
            };
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(this.updateAlerts);
    } else {
      const prevDuration = moment(previousValue.end).diff(previousValue.start);
      const nextDuration = moment(this.times.end).diff(this.times.start);
      if (prevDuration !== nextDuration) this.updateAlerts(this.times);
    }
  };

  ngOnDestroy(): void {
    this.alerts = [];
    this.removeOutline();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onProjectChange = (): void => {
    forkJoin([
      this.service.getStyles(),
      this.service.getRuleTypes(this.project.symbolicName),
    ]).subscribe((res) => {
      this.typeStyles = res[1]
        .map((type: RuleType) => ({
          ...type,
          ...res[0][type.group.toLowerCase()],
        }))
        .reduce((obj, item) => ((obj[item.id] = item), obj), {});
    });
  };

  updateAlerts = (times: Time): void => {
    const start = times.start.getTime();
    const end = times.end.getTime();
    this.error = false;

    this.service
      .getAlertHistory(this.project, start, end)
      .subscribe({
        next: (alerts: GenericAlert[]) => {
          this.alerts = alerts;
          this.loading = false;
        },
        error: (error) => {
          this.error = true;
          this.loading = false;
          console.error(error);
        }
      });
  };

  onAlertClick = (alert: GenericAlert): void => {
    this.selected = alert;
    this.dialog
      .open(this.detailsDialog)
      .afterClosed()
      .subscribe(() => (this.selected = null));
  };

  onShowMapsNotification(alert: GenericAlert, event: MouseEvent): void {
    event.stopPropagation();
    this.removeOutline();
    const bounds = extent({ ...alert.geoJSON });
    this.service.getLayerStyles().subscribe((layerStyles) => {
      const layers = this.mapgl.getStyle().layers;
      let firstSymbolId, firstLabelId;
      for (let i = 0; i < layers.length; i++) {
        if (!firstSymbolId && layers[i].type === 'symbol') {
          firstSymbolId = layers[i].id;
        }
        if (!firstLabelId && layers[i].id.includes('label')) {
          firstLabelId = layers[i].id;
        }
        if (firstSymbolId && firstLabelId) break;
      }

      this.mapgl
        .addLayer(
          {
            ...layerStyles.shapes,
            source: { type: 'geojson', data: alert.geoJSON },
          } as FillLayer,
          firstSymbolId
        )
        .addLayer(layerStyles.line as LineLayer, firstLabelId)
        .addLayer(layerStyles.points as SymbolLayer)
        .fitBounds(bounds, { padding: this.mapPadding });
      this.outlineId = alert.alertId;
    });
  }

  public removeOutline(event?: MouseEvent): void {
    if (event) event.stopPropagation();
    if (!!this.mapgl) {
      const lineId = 'temporary-shapes';
      const source = this.mapgl.getSource(lineId);
      this.outlineId = null;
      this.mapgl.removeLayer(lineId);
      this.mapgl.removeLayer('temporary-lines');
      this.mapgl.removeLayer('temporary-points');
      if (source) {
        this.mapgl.removeSource(lineId);
      }
    }
  }
}
