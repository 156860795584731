import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from 'ngx-flexible-layout';
import {
  MatBottomSheetRef,
  MatBottomSheet,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  template: `
    <h3>Disclaimer</h3>
    <p>
      Vieux & Associates' products and services include data gathering,
      integration, and complex processing of data from multiple sources. Since
      we do not control all sources and availability of data, and all
      conceivable protections and redundancies can not be implemented, the near
      real-time (NRT) data and analysis are provisional. Automated  standards
      and reasonable practices are applied. Professional review and correction
      has not been applied.
    </p>
    <p>
      NRT gauge-adjusted radar rainfall (GARR) is adjusted using automated
      algorithms but has not been reviewed and corrected by our hydrometeorology
      team.
    </p>
  `,
})
export class DisclaimerComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<DisclaimerComponent>
  ) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  maxtimesQuery: Subscription;
  breakpoint = '';
  profHeight = 2;
  gridLayout = { gutterSize: 8, columns: 1 };
  layouts = {
    xs: { col: 1, gutter: 8 },
    sm: { col: 2, gutter: 8 },
    md: { col: 3, gutter: 16 },
    lg: { col: 4, gutter: 16 },
    xl: { col: 5, gutter: 16 },
  };
  rxSubs: Subscription[] = [];

  constructor(
    private mediaObserver: MediaObserver,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    // this.rxSubs.push(this.mediaObserver.media$.subscribe(this.onMediaObserver));
    this.rxSubs.push(this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      this.breakpoint = changes[0]?.mqAlias;
    }));
  }

  ngOnDestroy(): void {
    this.rxSubs.forEach((s) => s.unsubscribe());
  }

  openBottomSheet(): void {
    this._bottomSheet.open(DisclaimerComponent);
  }
}
