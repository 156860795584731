import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '@app/core';
import { Project } from '@app/shared/models';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {

  rxSubs: Subscription[] = [];
  routes;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.rxSubs.push(
      this.userService.project$.subscribe(this.onCurrentProj)
    );
  }

  onCurrentProj = (proj: Project): void => {
    this.routes = this.router.config
      .filter(r => proj.products.includes(r.path))
      .map(r => ({ ...r, data: this.route.snapshot.data }));
  }

}
