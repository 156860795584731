<div
    class="message"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="loading || error"
    [class.error]="error">
  <mat-spinner diameter="40" *ngIf="loading"></mat-spinner>
  <span *ngIf="error">{{error}}</span>
</div>
<div [id]="chartId" [hidden]="loading || error"></div>
