import {
  Component,
  Input,
  OnInit,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { Project } from '@app/shared/models';
import { HyetographService } from './hyetograph.service';
import * as c3 from 'c3';
import * as moment from 'moment-timezone';
import { UserService } from '@app/core';
import { Time } from '@app/shared/models';
import { ReplaySubject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Component({
  selector: 'app-hyetograph',
  templateUrl: './hyetograph.component.html',
  styleUrls: ['./hyetograph.component.scss'],
})
export class HyetographComponent implements OnInit, OnDestroy, OnChanges {
  error: string;
  loading = true;
  chartId = 'chart-' + Date.now();

  private project: Project;
  private chart: c3.ChartAPI;
  private subscriptions = [];
  private chartWidth: number;
  private timestamp: number = null;
  private mosaics$ = new ReplaySubject<string[]>(1);
  private resizeHeight$ = new ReplaySubject<number>(1);
  private height: number;
  private isSoilMoisture: boolean;

  // for forecast hyetographs
  @Input() source: any;
  @Input() mosaicId: string;
  @Input() attributes: string[] = [];
  @Input() fakeNow = true;
  @Input() times: Time;
  @Input() duration = 72; // hours
  @Input() interval: number;
  private _id: string;
  @Input()
  get id(): string {
    return this._id;
  }
  set id(v: string) {
    if (v !== this._id) {
      this._id = v;
      if (!!this.chart) {
        this.loading = true;
        this.prepData();
      }
    }
  }
  @Input()
  set update(timestamp: number) {
    if (timestamp > this.timestamp) {
      if (this.timestamp !== null && !!this.chart) {
        this.prepData();
      }
      this.timestamp = timestamp;
    }
  }

  @HostListener('window:resize')
  onResize() {
    if (this.height !== this.elRef.nativeElement.offsetHeight) {
      this.height = this.elRef.nativeElement.offsetHeight;
      this.resizeHeight$.next(this.height);
    }
  }

  constructor(
    private userService: UserService,
    private service: HyetographService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.isSoilMoisture = this.attributes?.includes('soil-moisture') && !!this.mosaicId;
    this.subscriptions.push(
      this.userService.project$.subscribe((res) => {
        this.project = res;
        this.service
          .getMosaics(this.project.symbolicName)
          .subscribe((mosaics) => this.mosaics$.next(mosaics));
        setTimeout(this.loadChart);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.times) {
      this.loading = true;
      const duration = moment(this.times.end).diff(this.times.start, 'hour');
      if (duration > 0) this.duration = duration;
      if (!changes.times.isFirstChange()) {
        this.loadChart();
      }
    }
    if (changes.id) {
      setTimeout(this.loadChart);
    }
  }

  private loadChart = (): void => {
    this.chartWidth = this.elRef.nativeElement.offsetWidth - 50;
    const IntervalsInHr = 60 / (this.interval || this.project.interval);
    const barWidth = Math.floor(
      this.chartWidth / (this.duration * IntervalsInHr)
    );

    const configInput = {
      bindto: '#' + this.chartId,
      barWidth: barWidth / 2 > 2 ? barWidth / 2 - 0.5 : 1,
      timezone: this.project.timezone,
      elHeight: this.elRef.nativeElement.offsetHeight,
      elWidth: this.elRef.nativeElement.offsetWidth,
    };
    const rainUnit = this.project.getUnitConfig('rain').options[0].abbr;
    const chartConfig = this.isSoilMoisture ? this.service.getSMChartSettings(configInput) : this.service.getChartSettings(configInput, rainUnit);
    this.chart = c3.generate(chartConfig);
    this.prepData();
  };

  private prepData = (): void => {
    const hour = 1000 * 60 * 60;
    const end: number = this.times.end.getTime() || Date.now();
    const start = this.times.start
      ? this.times.start.getTime()
      : end - hour * this.duration;
    const config = {
      end,
      start,
      project: this.project.symbolicName,
      id: this.id,
    };

    if (this.source?.type === 'previeux') {
      const input = {
        project: this.project.symbolicName,
        radar: this.source.id,
        id: this.id,
        time: this.times.end.getTime(),
        basin: this.source.selectedShape ? this.source.selectedShape.id : null,
      };
      this.service
        .getPrevieuxHyetograph(input)
        .then(this.loadData)
        .catch(this.chartError);
    } else if (this.source?.type === 'nws') {
      const input = {
        project: this.project.symbolicName,
        product: this.source.id,
        id: this.id,
        time: this.times.realtime ? this.times.end.getTime() : null,
        basin: this.source.selectedShape ? this.source.selectedShape.id : null,
      };
      this.service
        .getNWSHyetograph(input)
        .then(this.loadData)
        .catch(this.chartError);
    } else if (this.isSoilMoisture) {
      this.service
        .getSMHydrograph(
          config.project,
          this.mosaicId,
          config.id,
          config.start,
          config.end)
        .then(this.loadSMData)
        .catch(this.chartError);
    }
    else if (
      this.attributes.length &&
      this.attributes.includes('mosaicOnly') &&
      this.mosaicId
    ) {
      this.service
        .getGarrHyetograph(
          config.project,
          this.mosaicId,
          config.id,
          config.start,
          config.end
        )
        .then(this.loadData)
        .catch(this.chartError);
    } else {
      this.mosaics$.pipe(take(1)).subscribe(([mosaic]) => {
        this.service
          .getHyetograph(this.project.symbolicName, start, end, this.id, mosaic)
          .then(this.loadData)
          .catch(this.chartError);
      });
    }
  };

  private chartError = (err) => {
    this.loading = false;
    this.error = 'Hyetograph unavailable';
    console.error(err.message || err);
  };

  private loadData = (data): void => {
    this.chart.unload();
    if (!!this.source) {
      const chartConfig = this.getJsonChartConfig(data.json);
      chartConfig.keys.value.push('cumulative', 'incremental');
      chartConfig.types.cumulative = 'line';
      chartConfig.colors = {
        cumulative: '#00838F',
        incremental: '#00BCD4',
      };
      chartConfig.names = {
        cumulative: `${this.source.type.toUpperCase()} Cumulative`,
        incremental: `${this.source.type.toUpperCase()} Incremental`,
      };
      this.chart.load(chartConfig);
    }
    if (data.gaugeHyetograph !== null) {
      const json = 'gauge' in data.json ? data.json.gauge : data.json;
      const chartConfig = this.getJsonChartConfig(json)
      chartConfig.keys.value.push('cumulative', 'incremental');
      chartConfig.types.cumulative = 'line';
      chartConfig.colors = {
        cumulative: '#00838F',
        incremental: '#00BCD4',
      };
      chartConfig.names = {
        cumulative: 'Gauge Cumulative',
        incremental: 'Gauge Incremental',
      };
      this.chart.load(chartConfig);
    }
    if (data.intersectingHyetograph !== null) {
      const json = 'intersect' in data.json ? data.json.intersect : data.json;
      const chartConfig = this.getJsonChartConfig(json)
      const mosaicOnly = this.attributes.includes('mosaicOnly');
      chartConfig.keys.value.push('iCumulative', 'iIncremental');
      chartConfig.types.iCumulative = 'line';
      chartConfig.colors = {
        iCumulative: '#ff8f00',
        iIncremental: '#ffc107',
      };
      chartConfig.names = {
        iCumulative: `${mosaicOnly ? '' : 'GARR '}Cumulative`,
        iIncremental: `${mosaicOnly ? '' : 'GARR '}Incremental`,
      };
      this.chart.load(chartConfig);
    }
    this.chart.axis.range({
      max: {
        y: data.maxCumulative > 0.5 ? data.maxCumulative : 0.5,
        y2: data.maxIncremental > 0.2 ? data.maxIncremental : 0.2,
      },
      min: { y: -0.01, y2: -0.01 },
    });
    this.subscriptions.push(
      this.resizeHeight$
        .pipe(debounceTime(250))
        .subscribe((height) => this.chart.resize({ height }))
    );
    this.loading = false;
  };

  private loadSMData = (data): void => {
    const chartConfig: any = {
      json: data,
      keys: { x: 'millis', value: [] },
      type: 'line',
      types: {},
      colors: {},
      names: {},
      unload: true,
      done: () => (this.loading = false),
    };
    chartConfig.keys.value.push('soilMoisture');
    chartConfig.colors = {
      soilMoisture: '#00838F',
    };
    chartConfig.names = {
      soilMoisture: 'Soil Moisture',
    };
    this.chart.axis.range({
      min: { y: 0.0 },
      max: { y: 1.0 },
    });

    this.chart.load(chartConfig);
    this.subscriptions.push(
      this.resizeHeight$
        .pipe(debounceTime(250))
        .subscribe((height) => this.chart.resize({ height }))
    );
  };

  private getJsonChartConfig = (data): any => {
    return {
        json: data,
        keys: { x: 'millis', value: [] },
        type: 'bar',
        types: {},
        colors: {},
        names: {}
    }
  }
}
