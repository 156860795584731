import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccessManagerService } from '../../../access-manager.service';
import { Group } from '@app/shared/models';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-groups',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  loading = true;
  error: string;
  editGroupForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public group: Group,
    private dialogRef: MatDialogRef<GroupComponent>,
    private amService: AccessManagerService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.loadGroup();
  }

  loadGroup() {
    this.loading = true;
    this.amService.getGroup(this.group.id)
      .subscribe({
        next: (res: Group) => {
          this.group = res;
          this.editGroupForm = this.fb.group(res);
          this.loading = false;
        },
        error: this.gqlError
      });
  }
  onSubmit() {
    this.loading = true;
    const g = this.editGroupForm.value;
    const changes = {
      id: g.id,
      name: g.name,
      description: g.description
    };
    this.amService.updateGroup(changes)
      .subscribe({
        next: () => {
          this.closeDialog({
            type: 'update',
            data: changes
          });
        },
        error: this.gqlError
      });
  }

  closeDialog = (data?: any) => {
    this.dialogRef.close(data);
  }

  gqlError = (error) => {
    this.error = error.message || error;
    this.editGroupForm.markAsPristine();
    this.loading = false;
  }

}
