import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { SidenavService, RouteDataService, UserService } from '@app/core';
import { MediaObserver } from 'ngx-flexible-layout';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  title = '';
  hideNav: boolean;
  awaitingQueryParams: boolean;
  embed: boolean;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private routeData: RouteDataService,
    private titleService: Title,
    public sidenav: SidenavService,
    public media: MediaObserver,
    public userService: UserService
  ) {
    iconRegistry
      .addSvgIcon(
        'vlogo',
        sanitizer.bypassSecurityTrustResourceUrl('./assets/images/v-logo.svg')
      );
  }

  ngOnInit(): void {
    this.routeData.getData().subscribe((data): void => {
      this.title = data.title;
      this.hideNav = data.hideNav;
      this.titleService.setTitle(this.title);
    });

    //Wait for query parameters to arrive, but only wait so long (in case the connection is interrupted)
    this.awaitingQueryParams = true;
    setTimeout(() => {
      this.awaitingQueryParams = false;
    }, 1000);

    //Parse query params when they arrive
    this.routeData.getQueryParams().subscribe((queryParams: Params) => {
      this.awaitingQueryParams = false;
      this.embed = queryParams['embed']?.toLowerCase() === 'true';
    });
  }
}
