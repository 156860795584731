import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileElementComponent } from './profile-element.component';
import { AvatarModule } from '@app/shared/avatar/avatar.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { ResourceGroupListComponent } from '../resource-group-list/resource-group-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [ProfileElementComponent, ResourceGroupListComponent],
  exports: [ProfileElementComponent],
  imports: [
    CommonModule,
    AvatarModule,
    FlexLayoutModule,

    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule
  ]
})
export class ProfileElementModule { }
