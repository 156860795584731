<div class="users-container" fxFill fxLayout="column">
  <div class="users-loading-shade" *ngIf="loading">
    <mat-spinner diameter="80"></mat-spinner>
  </div>

  <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center" *ngIf="error">
    <div class="error mat-elevation-z2"
        fxFlex
        fxFlex.sm="60"
        fxFlex.md="40"
        fxFlex.gt-md="20">
      <h3>Error</h3>
      <p>{{error}}</p>
    </div>
  </div>

  <div fxFlex fxLayout="column" [hidden]="!error">
    <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="table-tools-container">
      <mat-form-field fxFlex="100" fxFlex.gt-sm="60" fxFlex.gt-md="40" fxFlex.gt-lg="25">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        <mat-icon matSuffix>filter_list</mat-icon>
      </mat-form-field>
      <div class="tools-button-container" fxFlex fxLayoutAlign="end center" fxLayoutGap="16px">
        <button mat-raised-button (click)="openNewUserDialog()" *ngIf="isAdmin">New User</button>
      </div>
    </div>

    <div class="users-table-container">
      <mat-table class="users-table" [dataSource]="dataSource" matSort fxFlex fxLayout="column">

        <ng-container *ngFor="let col of columns" matColumnDef="{{col.def}}">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{col.name || col.def}}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div [class.truncate]="col.truncate">{{getProperty(row, col.def)}}</div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></mat-header-row>
        <mat-row
            class="clickable-row"
            *matRowDef="let row; columns: getDisplayedColumns();"
            [class.clicked-row]="selectedRow === row"
            (click)="openDetailDialog(row)"
            ></mat-row>
      </mat-table>
    </div>

    <mat-paginator [length]="dataSource.data.length"
        [pageSize]="25"
        [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>