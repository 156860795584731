import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { AuthService, UserService } from './core';
import { onAuthUIStateChange, AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Subscription } from 'rxjs';
import { TimepickerService } from './shared/timepicker/timepicker.service';
import { environment } from '@env/environment';
import { Project } from './shared/models';
import { MFAService } from './core/mfa/mfa.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

declare const gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  projSubs: Subscription[] = [];
  signedIn = false;
  vaiUser = false;
  error = false;
  project: Project;

  public confirmError = false;
  public showVerify = false;
  codeSent = false;
  user: CognitoUserInterface | undefined;
  authState: AuthState;

  public confirmForm: UntypedFormGroup

  constructor(
    private userService: UserService,
    private router: Router,
    private tpService: TimepickerService,
    private fb: UntypedFormBuilder,
    private mfaService: MFAService,
    private ref: ChangeDetectorRef,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    onAuthUIStateChange(this.handleStateChange);
    this.confirmForm = this.fb.group({
      code: ['', [Validators.required]]
    });

    /* Google Analytics */
    gtag('config', 'UA-35239755-5', {
      debug_mode: +!environment.production,
      custom_map: {
        dimension1: 'org',
        dimension2: 'proj',
        dimension3: 'internal',
      },
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) =>
          gtag('config', 'UA-35239755-5', {
            page_path: event.urlAfterRedirects,
          }),
        error: (e) => console.error(e),
      });

    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: '1 minute',
        mm: '%d minutes',
        h: '1 hour',
        hh: '%d hours',
        d: '24 hours',
        dd: '%d days',
        M: '1 month',
        MM: '%d months',
        y: '1 year',
        yy: '%d years',
      },
    });

    this.userService.currentUser.subscribe((vaiUser) => (this.vaiUser = !!vaiUser));
    this.userService.project$.subscribe((proj) => {
      this.clearSubs();
      this.tpService.maxtimes = [];
      this.project = proj;
      this.maxtimeSub();
      if (proj.products.includes('rules-manager')) {
        this.projSubs.push(
          this.tpService.alertsObs$(proj.symbolicName).subscribe()
        );
      }
    });
  }

  private maxtimeSub = () => {
    if (!this.signedIn) return;
    this.projSubs.push(
      this.tpService
        .maxtimesObs$(this.project)
        .subscribe({ complete: this.maxtimeSub })
    );
  };

  private handleStateChange = (authState: AuthState, data?: object): void => {
    this.authState = authState;
    this.user = data as CognitoUserInterface;

    switch (authState) {
      case AuthState.SignedIn:
        if (!this.signedIn) {
          this.verify();
        }
        break;
      default:
        this.vaiUser = null;
        this.clearSubs();
        this.signedIn = false;
        this.codeSent = false;
        this.confirmError = false;
        this.showVerify = false;
        this.confirmForm.get('code').setValue('');
        break;
    }
  };

  private verify = (): void => {
    this.mfaService.requiresOTP(this.user.username).subscribe((res) => {
      const mfaEnabledForUser = res['required'];
      const otpStepNeeded = mfaEnabledForUser && this.authService.isOtpStepNeeded();
      if(!otpStepNeeded) {
        this.populateVaiUser();
      } else if (!this.codeSent) {
        this.showVerify = true;
        this.codeSent = true;
        this.mfaService.requestOTP(this.user.username).subscribe();
      }
      this.ref.detectChanges();
    }, (error) => {
      // Something went wrong. Attempt to authenticate without MFA, which will fail more gracefully.
      console.error(error);
      this.populateVaiUser();
    });
  }

  private populateVaiUser = (): void => {
    this.signedIn = true;
    this.userService.myUser(true).subscribe({
      next: (vaiUser) => {
        if (
          vaiUser.company &&
          vaiUser.company.projects &&
          vaiUser.company.projects.length
          ) {
            this.userService.setUser(vaiUser);
            this.userService.setCurrentProject();
          } else this.error = true;
        },
        error: () => (this.error = true)
      });
    }

  onResend = (): void => {
    this.mfaService.requestOTP(this.user.username).subscribe();
  }

  onSubmit = (): void => {
    this.mfaService.validateOTP(this.user.username, this.confirmForm.get('code').value)
      .subscribe({
        next: (res) => {
          this.authService.saveOtpCompleted();
          this.populateVaiUser()
        },
        error: (e) => {
          this.confirmError = true
          this.ref.detectChanges();
        },
      }
    );
  };

  private clearSubs = (): void => {
    this.projSubs.forEach((s) => s.unsubscribe());
    this.projSubs = [];
  };
}
